import { AnchorHTMLAttributes, FC } from 'react'

import { twJoin, twMerge } from 'tailwind-merge'

type Props = {
  href?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const Link: FC<Props> = ({ href, className, children, ...props }) => {
  return (
    <a
      className={twMerge(
        twJoin(
          'text-base leading-[140%] text-link focus:underline',
          href !== undefined
            ? 'outline-1 outline-brand visited:text-link-visited hover:underline hover:opacity-70 active:underline active:opacity-70'
            : 'text-grayText outline-0',
        ),
        className,
      )}
      href={href}
      {...props}
    >
      {children}
    </a>
  )
}
